<template>
  <div>
    <div class="row mb-3">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div
                class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6"
              >
                <b-row class="my-1 d-flex align-items-center">
                  <b-col sm="3">
                    <label>Project Code :</label>
                  </b-col>
                  <b-col sm="9">
                    <input
                      type="text"
                      class="form-control"
                      size="sm"
                      v-model="filter.project_code"
                    />
                  </b-col>
                </b-row>
                <b-row class="my-1 d-flex align-items-center">
                  <b-col sm="3">
                    <label>Nickname :</label>
                  </b-col>
                  <b-col sm="9">
                    <input
                      type="text"
                      class="form-control"
                      size="sm"
                      v-model="filter.project_nickname"
                    />
                  </b-col>
                </b-row>
              </div>
              <div
                class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6"
              >
                <b-row class="my-1 d-flex align-items-center">
                  <b-col sm="3">
                    <label>Transmittal Number :</label>
                  </b-col>
                  <b-col sm="9">
                    <input
                      type="text"
                      class="form-control"
                      size="sm"
                      v-model="filter.transmittal_number"
                    />
                  </b-col>
                </b-row>
                <b-row class="my-1 d-flex align-items-center">
                  <b-col sm="3">
                    <label>Company :</label>
                  </b-col>
                  <b-col sm="9">
                    <input
                      type="text"
                      class="form-control"
                      size="sm"
                      v-model="filter.company_name"
                    />
                  </b-col>
                </b-row>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <div class="text-right">
                  <button class="btn btn-primary btn-md" @click="onCariData">
                    <i class="flaticon2-search-1"></i> Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-right mb-2">
              <button
                class="btn btn-sm btn-primary"
                @click.prevent="addTransmittal()"
              >
                <i class="fas fa-plus"></i>
              </button>
            </div>
            <div class="table-responsive">
              <complete-table
                :loading="this.$store.getters.loadingTransmittal"
                :data="this.$store.getters.getterTransmittal"
              >
                <template #header>
                  <tr>
                    <th>Company</th>
                    <th>Subject</th>
                    <th>Project Code</th>
                    <th>Nickname</th>
                    <th>Transmittal Date</th>
                    <th>Transmittal Number</th>
                    <th>Action</th>
                  </tr>
                </template>
                <!-- <template
                  v-if="this.$store.getters.getterTransmittal.length == 0"
                >
                  <template v-if="isEmpty">
                    <tbody>
                      <tr>
                        <td class="text-center" colspan="7">
                          <div class="text-center">
                            <i
                              class="flaticon2-open-box text-secondary icon-3x"
                            ></i>
                            <h4>
                              Data Empty.
                            </h4>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                  <template v-if="isLoading">
                    <tbody>
                      <tr>
                        <td colspan="7" class="text-center">
                          <div>
                            <b-spinner
                              label="Loading..."
                              variant="primary"
                            ></b-spinner>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </template> -->

                <template #defaultBody="{ item, i }">
                  <tr>
                    <td>
                      <span
                        class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                        >{{ item.company.name }}
                      </span>
                    </td>

                    <td>
                      <span
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{ item.subject }}
                      </span>
                    </td>
                    <td>
                      <span
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{ item.project_code }}
                      </span>
                    </td>
                    <td>
                      <span
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{ item.project.nickname }}
                      </span>
                    </td>
                    <td>
                      <span
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{
                          moment(item.date).isValid() ? 
                          moment(item.date).format("DD-MM-YYYY") : "" }}
                      </span>
                    </td>
                    <td>
                      <span
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{ item.number }}
                      </span>
                    </td>
                    <td style="cursor: pointer;">
                      <div
                        class="text-center"
                        @click.prevent="editTransmittal(item.id)"
                      >
                        <i class="fas fa-edit text-primary"></i>
                      </div>
                    </td>
                  </tr>
                </template>
              </complete-table>
            </div>

            <!-- <div
              class="text-right"
              v-if="this.$store.getters.getterTransmittal.length < 5"
            >
              <div class="btn-group">
                <button class="btn btn-primary btn-sm">Prev</button>
                <button class="btn btn-primary btn-sm">Next</button>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_TRANSMITTAL } from "@/core/services/store/transmittal.module";
import CompleteTable from "../../../content/widgets/advance-table/CompleteTable.vue";

export default {
  components: { CompleteTable },
  name: "Transmital",
  data() {
    return {
      filter: {
        project_code: null,
        project_nickname: null,
        transmittal_number: null,
        company_name: null,
      },
      isEmpty: true,
      isLoading: false,
    };
  },
  async mounted() {
    this.isLoading = true;
    this.isEmpty = false;
    this.onFilterData(this.filter);
    this.isLoading = false;
    this.isEmpty = false;
  },
  methods: {
    onCariData() {
      this.onFilterData(this.filter);
    },
    onFilterData(param) {
      const filter = {
        params: {
          project_code: param.project_code === null ? "" : param.project_code,
          project_nickname:
            param.project_nickname === null ? "" : param.project_nickname,
          transmittal_number:
            param.transmittal_number === null ? "" : param.transmittal_number,
          company_name: param.company_name === null ? "" : param.company_name,
        },
      };
      this.$store.dispatch(GET_TRANSMITTAL, filter);
    },

    addTransmittal() {
      this.$router.push("/project/transmittal/create");
    },

    editTransmittal(id) {
      this.$router.push("/project/transmittal/" + id + "/edit");
    },
  },
};
</script>
